exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categorias-js": () => import("./../../../src/pages/categorias.js" /* webpackChunkName: "component---src-pages-categorias-js" */),
  "component---src-templates-all-posts-js": () => import("./../../../src/templates/allPosts.js" /* webpackChunkName: "component---src-templates-all-posts-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-archive-tags-js": () => import("./../../../src/templates/archiveTags.js" /* webpackChunkName: "component---src-templates-archive-tags-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

